
import { Vue, Component } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgTaxaBoleto extends Vue {

    filter: {
        empreendimentoId: [];
        dataPagamentoInicial: string;
        dataPagamentoFinal: string;
        empresaId:[];
        dataVencimentoInicial:string,
        dataVencimentoFinal:string,
        numeroContrato:string,
    } = {
        empreendimentoId: [],
        dataPagamentoInicial: '',
        dataPagamentoFinal:'', 
        empresaId:[],
        dataVencimentoInicial:'',
        dataVencimentoFinal:'',
        numeroContrato:'',
    }; 

    valid = true;
    empreendimentos: any = [];
    empresas: any = [];

    get selecionarTodosEmpreendimentos(){
        return this.filter.empreendimentoId.length === this.empreendimentos.length
    }

    get SelecionarAlgunsEmpreendimentos(){
        return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    get iconEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
        return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpreendimentos) {
                this.filter.empreendimentoId = []
            } else {
                this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
            }
        })
    }

    SelecionarEmpresa(){
      this.$nextTick(() => {
        if (this.selecionarTodosEmpresas) {
          this.filter.empresaId = []
        } else {
          this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
        }
      })
    }

    get selecionarTodosEmpresas(){
      return this.filter.empresaId.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas(){
      return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
    }

    get iconEmpresa(){
      if (this.selecionarTodosEmpresas) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.selecionarTodosEmpresas) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
      return 'Selecionar todos'
    } 

    mounted() { 
        new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
            res => {
                this.empresas = res.data.items;
            }
        );

        new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
            (res) => {
                this.empreendimentos = res.data.items;
            }
        ); 
    } 

    Visualizar() {
        if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal)){
            this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
            return true;
        }      
        let routeLink = this.$router.resolve({ 
        name: "RelTaxaBoleto",
            query: {
                empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
                empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
                numeroContrato: this.filter.numeroContrato ? this.filter.numeroContrato.toString() : "",
                dataVencimentoInicial: this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial.toString() : "",
                dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal.toString() : "",
                dataPagamentoInicial: this.filter.dataPagamentoInicial ? this.filter.dataPagamentoInicial.toString() : "",
                dataPagamentoFinal: this.filter.dataPagamentoFinal ? this.filter.dataPagamentoFinal.toString() : "",
            },
        });
        window.open(routeLink.href, "_blank");
    }
}
